import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SaleinvoiceService } from '../saleinvoice.service';
import { FilterService } from 'app/filter.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sale-invoice-list',
  templateUrl: './sale-invoice-list.component.html',
  styleUrls: ['./sale-invoice-list.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class SaleInvoiceListComponent implements OnInit {
  selectedCompany: number;
  customerList: any;
  @BlockUI() blockUI: NgBlockUI;

  public selectedOption = 10;
  public invoices: any = [];
  public data: any;
  public previousCompanyFilter = '';
  private tempData = [];
  public searchValue = '';
  public tempFilterData;
  public previousStatusFilter = '';
  public rows;

  filter: any = { pageNumber: 1, pageSize: 10, sortColumn: '', sortOrder: '', searchText: '' }


  page: number = 1;
  pageSize: number;
  sortColumn: string = '';
  sortOrder: string;
  totalRecords: number;
  currentIndex: any;


  constructor(private saleinvoiceService: SaleinvoiceService,
    private filterService: FilterService,
    private router: Router,) { }

  ngOnInit(): void {

    this.filterService.selectedCompany.subscribe(companyId => {
      if (this.router.url == "/sale-List") {
        this.getData();
      }
    });
  }



  GetCustomersList() {
    this.selectedCompany = Number(localStorage.getItem('selectedCompany'));
    if (this.selectedCompany > 0)
      this.saleinvoiceService.GetCustomersList(this.selectedCompany).subscribe(obj => {
        if (obj.isSuccess) {
          this.customerList = obj.data;
        }
      })
  }

  getData() {
    
    this.blockUI.start();
    this.saleinvoiceService.getSaleInvoiceList(this.filter).subscribe(response => {
      this.blockUI.stop();
      if (response.isSuccess == true) {
        this.data = response?.data?.data?.saleInvoice;
        this.rows = this.data;
        this.tempData = this.rows;
        this.tempFilterData = this.rows;
        this.pageSize = response?.data?.data?.pageSize;
        this.totalRecords = response?.data?.data?.totalRecords;
      }
    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong, please try again!',
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-danger ml-1'
        }
      });
      this.blockUI.stop();
    });
  }


  filterData() {
    
    // this.rows = this.tempFilterData.filter(x => {
    //   return (
    //     ((!this.filter.saleInvoiceId || x.saleInvoiceId == this.filter.saleInvoiceId)) &&
    //     (
    //       (!this.filter.text) ||
    //       (
    //         (
    //           (x.invoiceNo?.toLowerCase().includes(this.filter.text.toLowerCase().trim())) ||
    //           (x.payeeReference?.toLowerCase().includes(this.filter.text.toLowerCase().trim())) ||
    //           (x.customer?.customerName.toLowerCase().includes(this.filter.text.toLowerCase().trim()))
    //         )
    //       )
    //     )
    //   )

    // });
    this.getData();
  }


  onPageChange(event) {
    this.filter.pageNumber = event;
    this.getData();
  }
  changePageSize() {
    this.getData();
  }

  onSort(event) {
    this.filter.sortColumn = event.column.prop;
    this.filter.sortOrder = event.newValue;
    this.getData();
  }

  removeInvoice(saleInvoiceId) {
    this.blockUI.start();
    this.saleinvoiceService.deleteSaleInvoice(saleInvoiceId).subscribe((res) => {
      var index = this.rows.findIndex(x => x.saleInvoiceId == saleInvoiceId);
      if (index !== -1) {
        this.rows.splice(index, 1);
      }
      if (res.isSuccess === true) {
        this.blockUI.stop();
        this.getData();
      }

    })
  }

  ConfirmTextOpen(saleInvoiceId) {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      if (result.value) {
        this.removeInvoice(saleInvoiceId);
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'Invoice has been deleted!',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        });
      }
    });
  }

  onRowDoubleClick(row: any) {
    const rowId = row.saleInvoiceId;  
    const link = `/saleinvoiceadd/${rowId}`; 
    this.router.navigate([link]);
  }

}
