import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private _httpClient: HttpClient) { }

  getUsers() {
    return this._httpClient.get<any>(`${environment.apiUrl}/User/GetUser`)
  }
  getUserById(userId) {
    return this._httpClient.get<any>(`${environment.apiUrl}/User/GetUserById?userId=` + userId)
  }
  addUpdateUser(data: any) {

    return this._httpClient.post<any>(`${environment.apiUrl}/User/AddUpdateUser`, data);
  }

  suspendUser(userId: any) {
    return this._httpClient.delete<any>(`${environment.apiUrl}/User/SuspendUser?userId=` + userId);
  }
  lockedUser(userId: any, isLocked: boolean) {
    return this._httpClient.get<any>(`${environment.apiUrl}/User/LockedUser?userId=${userId}&isLocked=${isLocked}`);
  }
  updatePassword(data: any) {
    return this._httpClient.post<any>(`${environment.apiUrl}/User/UpdatePassword`, data);
  }
  getAllCompany() {
    return this._httpClient.get<any>(`${environment.apiUrl}/User/GetAllCompany`);
  }

  getUsersList(companyId) {
    return this._httpClient.get<any>(`${environment.apiUrl}/User/GetUsersList?companyId=` + companyId)
  }

  getAllUsers(data) {
    return this._httpClient.post<any>(`${environment.apiUrl}/User/GetAllUsers`, data);
  }
}
