import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SaleinvoiceService {

    constructor(private _httpClient: HttpClient) { }

    getSaleInvoiceList(data) {
        return this._httpClient.post<any>(`${environment.apiUrl}/SaleInvoice/GetAllSaleInvoices`, data);
    }


    GetCustomersList(companyId) {
        return this._httpClient.get<any>(`${environment.apiUrl}/Customer/GetCustomersList?companyId=` + companyId)
    }
    addUpdateSaleInvoice(data: any) {
        return this._httpClient.post<any>(`${environment.apiUrl}/SaleInvoice/AddUpdateSaleInvoice`, data);
    }

    deleteSaleInvoice(saleInvoiceId: any) {
        return this._httpClient.delete<any>(`${environment.apiUrl}/SaleInvoice/DeleteSaleInvoice?saleInvoiceId=` + saleInvoiceId);
    }

    GetSaleInvoiceById(saleInvoiceId) {
        return this._httpClient.get<any>(`${environment.apiUrl}/SaleInvoice/GetSaleInvoiceById?saleInvoiceId=` + saleInvoiceId);
    }
    //   getAllBusinessType()
    //   {
    //     return this._httpClient.get<any>(`${environment.apiUrl}/Supplier/GetAllBusinessType`);
    //   }


}
