import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private selectedCompanySource = new BehaviorSubject<number>(0);
  selectedCompany = this.selectedCompanySource.asObservable();

  constructor() { }

  setSelectedCompany(companyId: number) {
    this.selectedCompanySource.next(companyId);
  }
}
