import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: 'home',
    url: 'dashboard',
  },
  // Pages
  {
    id: 'setup',
    title: 'Setup',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    admin:true,
    icon: 'settings',
    children: [
      {
        id: 'companyList',
        title: 'Companies',
        type: 'item',
        icon: 'briefcase',
        url: 'company/companyList',
      },
      {
        id: 'usersList',
        title: 'Users',
        type: 'item',
        icon: 'user',
        url: 'users/usersList',
      },
      {
        id: 'categoryList',
        title: 'Categories',
        type: 'item',
        icon: 'type',
        url: 'category/categoryList',
      },
      {
        id: 'chartAccountList',
        title: 'Chart of Accounts',
        type: 'item',
        icon: 'user',
        url: 'chartofaccount/chartAccountList',
      },
      {
        id: 'projectTypeList',
        title: 'Project Types',
        type: 'item',
        icon: 'user',
        url: 'projectType/projectTypeList',
      },
      {
        id: 'supplierTypeList',
        title: 'Contact Types',
        type: 'item',
        icon: 'user',
        url: 'supplierType/supplierTypeList',
      },
      {
        id: 'jobTypeList',
        title: 'Job Types',
        type: 'item',
        icon: 'user',
        url: 'jobType/jobTypeList',
      },
      {
        id: 'paymentType',
        title: 'Payment Types',
        type: 'item',
        icon: 'user',
        url: 'payment/paymentTypeList',
      }
    ]
  },
  {
    id: 'setupcompanyadmin',
    title: 'Setup',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    admin:false,
    icon: 'settings',
    children: [
      {
        id: 'usersList',
        title: 'Users',
        type: 'item',
        icon: 'user',
        url: 'users/usersList',
      },
      {
        id: 'categoryList',
        title: 'Categories',
        type: 'item',
        icon: 'type',
        url: 'category/categoryList',
      },
      {
        id: 'chartAccountList',
        title: 'Chart of Accounts',
        type: 'item',
        icon: 'user',
        url: 'chartofaccount/chartAccountList',
      },
      {
        id: 'projectTypeList',
        title: 'Project Types',
        type: 'item',
        icon: 'user',
        url: 'projectType/projectTypeList',
      },
      {
        id: 'supplierTypeList',
        title: 'Contact Types',
        type: 'item',
        icon: 'user',
        url: 'supplierType/supplierTypeList',
      },
      {
        id: 'jobTypeList',
        title: 'Job Types',
        type: 'item',
        icon: 'user',
        url: 'jobType/jobTypeList',
      },
      {
        id: 'paymentType',
        title: 'Payment Types',
        type: 'item',
        icon: 'user',
        url: 'payment/paymentTypeList',
      }
    ]
  },
  {
    id: 'setupuser',
    title: 'Setup',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    admin:false,
    icon: 'settings',
    children: [
      {
        id: 'categoryList',
        title: 'Categories',
        type: 'item',
        icon: 'type',
        url: 'category/categoryList',
      },
      {
        id: 'chartAccountList',
        title: 'Chart of Accounts',
        type: 'item',
        icon: 'user',
        url: 'chartofaccount/chartAccountList',
      },
      {
        id: 'projectTypeList',
        title: 'Project Types',
        type: 'item',
        icon: 'user',
        url: 'projectType/projectTypeList',
      },
      {
        id: 'supplierTypeList',
        title: 'Contact Types',
        type: 'item',
        icon: 'user',
        url: 'supplierType/supplierTypeList',
      },
      {
        id: 'jobTypeList',
        title: 'Job Types',
        type: 'item',
        icon: 'user',
        url: 'jobType/jobTypeList',
      },
      {
        id: 'paymentType',
        title: 'Payment Types',
        type: 'item',
        icon: 'user',
        url: 'payment/paymentTypeList',
      }
    ]
  },
  {
    id: 'setup',
    title: 'Setup',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    admin:false,
    icon: 'settings',
    children: [
      {
        id: 'categoryList',
        title: 'Categories',
        type: 'item',
        icon: 'type',
        url: 'category/categoryList',
      },
      {
        id: 'chartAccountList',
        title: 'Chart of Accounts',
        type: 'item',
        icon: 'user',
        url: 'chartofaccount/chartAccountList',
      },
      {
        id: 'projectTypeList',
        title: 'Project Types',
        type: 'item',
        icon: 'user',
        url: 'projectType/projectTypeList',
      },
      {
        id: 'supplierTypeList',
        title: 'Contact Types',
        type: 'item',
        icon: 'user',
        url: 'supplierType/supplierTypeList',
      },
      {
        id: 'jobTypeList',
        title: 'Job Types',
        type: 'item',
        icon: 'user',
        url: 'jobType/jobTypeList',
      },
      {
        id: 'paymentType',
        title: 'Payment Types',
        type: 'item',
        icon: 'user',
        url: 'payment/paymentTypeList',
      }
    ]
  },



  {
    id: 'contact',
    title: 'Contacts',
    type: 'item',
    icon: 'user-plus',
    url: 'supplier/supplierList',

  },

  {
    id: 'item',
    title: 'Items',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'layers',
    children: [
      {
        id: 'projectGroupList',
        title: 'Project Group',
        type: 'item',
        icon: 'paperclip',
        url: 'projectgroup/projectgrouplist',
      },
      {
        id: 'projectList',
        title: 'Projects',
        type: 'item',
        icon: 'paperclip',
        url: 'project/projectList',
      },
      {
        id: 'jobList',
        title: 'Jobs',
        type: 'item',
        icon: 'file-text',
        url: 'job/jobList',
      },
    ]
  },


  {
    id: 'Invoicing',
    title: 'Invoicing',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'dollar-sign',
    children: [
      {
        id: 'invoice-list',
        title: 'Purchase Invoice',
        type: 'item',
        icon: 'briefcase',
        url: 'invoice/invoice-list',
      },
      {
        id: 'sale-list',
        title: 'Sale Ledger',
        type: 'item',
        icon: 'briefcase',
        url: '/sale-List',
      },
    ]
  },
  {
    id: 'Export/Import',
    title: 'Export / Import',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'upload',
    children: [
      {
        id: 'import-contacts',
        title: 'Import Contacts',
        type: 'item',
        icon: 'download',
        url: 'import-contacts/import-contacts',
      },
      {
        id: 'export-xero-list',
        title: 'Export Xero Bills',
        type: 'item',
        icon: 'download',
        url: 'export-xero-list/export-xero-list',
      },
      {
        id: 'exportcsvlog',
        title: 'Export Xero Log',
        type: 'item',
        icon: 'eye',
        url: 'exportcsvlog/exportcsvlog',
      }
    ]
  },
  {
    id: 'payment',
    title: 'Payments',
    translate: 'MENU.DASHBOARD.COLLAPSIBLE',
    type: 'collapsible',
    icon: 'credit-card',
    children: [
      {
        id: 'paymentList',
        title: 'Approve Payments',
        type: 'item',
        icon: 'package',
        url: 'payment/paymentList',
      },
      // {
      //   id: 'reconcilepayments',
      //   title: 'Reconcile Payments',
      //   type: 'item',
      //   icon: 'package',
      //   url: 'payment/reconcilepayments',
      // },
      {
        id: 'paymentListActual',
        title: 'Payments list',
        type: 'item',
        icon: 'package',
        url: 'payment/payments',
      },
      {
        id: 'payoutList',
        title: 'Payout list',
        type: 'item',
        icon: 'package',
        url: 'payment/payoutList',
      },
      {
        id: 'paymentList',
        title: 'Bank',
        type: 'item',
        icon: 'package',
        url: 'bank/bankList',
      },
    ]
  },

];
