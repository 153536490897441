import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NoteHistoryModalComponent } from 'app/purchaseinvoice/note-history-modal/note-history-modal.component';
import { PurchaseInvoiceService } from 'app/purchaseinvoice/purchaseinvoice.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-oktopay',
  templateUrl: './oktopay.component.html',
  styleUrls: ['./oktopay.component.scss']
})
export class OktopayComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  purchaseInvoideId: number;
  invoiceObj: any;
  // isChecked: boolean = false;
  subTotals: any;
  vatAmounts: any;
  totalInclVats: any;
  isChecked1: boolean = false;
  isChecked2: boolean = false;
  selectedCheckboxId: number = 0;
  isNotedisabled = false;
  noteList: any;
  note: string = ''; // this field use for note saving

  constructor(private _ActivatedRoute: ActivatedRoute,
    private _PurchaseInvoiceService: PurchaseInvoiceService,
    private router: Router,
    private modalService: NgbModal,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.getPurchaseInvoideIdFromInovices();
  }


  onChange(checkboxId) {
    if (checkboxId === 3) {
      this.isChecked1 = !this.isChecked1;
      this.isChecked2 = false; // Uncheck the other checkbox
      this.selectedCheckboxId = 3;
    } else if (checkboxId === 4) {
      this.isChecked2 = !this.isChecked2;
      this.isChecked1 = false; // Uncheck the other checkbox
      this.selectedCheckboxId = 4;
    }
  }





  getPurchaseInvoideIdFromInovices() {
    this.purchaseInvoideId = Number(this._ActivatedRoute.snapshot.paramMap.get('id'));
    if (this.purchaseInvoideId) {
      this.getInvoiceById(this.purchaseInvoideId);
      this.getNoteHistory();
    }
  }

  getInvoiceById(purchaseInvoideId) {
    this._PurchaseInvoiceService.GetPurchaseInoviceWithDetailById(purchaseInvoideId).subscribe(obj => {

      this.invoiceObj = obj?.data;
      this.subTotals = obj?.data?.netAmounts;
      this.vatAmounts = obj?.data?.vatAmounts;
      this.totalInclVats = obj?.data?.totalInclVats;
    })
  }

  AddNote() {
    const invoiceId = Number(this._ActivatedRoute.snapshot.paramMap.get('id'));
    if (invoiceId > 0) {
      this.blockUI.start();
      this._PurchaseInvoiceService.UpdateInoviceStatus(invoiceId, 0, this.note, null).subscribe(res => {
        this.blockUI.stop();
        if (res.isSuccess === true) {
          Swal.fire({
            icon: 'success',
            title: 'Note saved',
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            }
          });
          this.router.navigate(['/invoice/invoice-list']);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong, please try again!',
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-danger ml-1'
            }
          });
        }
      });
    }
  }


  async updateStatus(status: string) {
    let newStatusId: number;

    if (status === 'hold') {
      newStatusId = 4; // Set the statusId for "Hold" status
    } else if (status === 'oktopay') {
      newStatusId = 3; // Set the statusId for "oktopay" status
    }

    const result = await Swal.fire({
      title: status === 'hold' ? 'The invoice status will change to hold. would you like to continue?' : 'The invoice status will change to ok to pay. would you like to continue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    });

    if (result.isConfirmed) {
      this.blockUI.start();
      const invoiceId = Number(this._ActivatedRoute.snapshot.paramMap.get('id'));
      this._PurchaseInvoiceService.UpdateInoviceStatus(invoiceId, newStatusId, '', null).subscribe(resp => {
        this.blockUI.stop();
        if (resp.isSuccess) {
          Swal.fire({
            icon: 'success',
            title: 'Status Updated!',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          });
          this.router.navigate(['/invoice/invoice-list']);
        }
      }, err => {
        this.blockUI.stop();
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: err.message,
          customClass: {
            confirmButton: 'btn btn-danger'
          }
        });
      });
    }

  }


  backToList() {
    if (this.location.getState() === null) {
      this.router.navigate(['/invoice/invoice-list']);
    } else {
      window.history.back();
    }
  }

  async getNoteHistory() {
    this.blockUI.start();
    const obj = await this._PurchaseInvoiceService.getNoteHistory(this.purchaseInvoideId).toPromise();
    this.blockUI.stop();
    if (obj.isSuccess) {
      this.noteList = obj.data;
      if (obj?.data?.length > 0) this.isNotedisabled = true;
    }
  }

  openModal() {
    const modalRef = this.modalService.open(NoteHistoryModalComponent, { backdrop: 'static' });
    if (this.purchaseInvoideId > 0) {
      modalRef.componentInstance.purchaseInvoiceId = this.purchaseInvoideId;
    }
    modalRef.result.then((result) => {
      if (result === 'success') {
        this.getNoteHistory();
      }

    });
  }

}
