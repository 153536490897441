import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private _httpClient: HttpClient) { }

  getCompanies() {
    return this._httpClient.get<any>(`${environment.apiUrl}/Company/GetCompanies`)
  }

  getEntireCompanies() {
    return this._httpClient.get<any>(`${environment.apiUrl}/Company/GetEntireCompanies`)
  }
  getCompanyById(companyId) {
    return this._httpClient.get<any>(`${environment.apiUrl}/Company/GetCompanyById?companyId=` + companyId)
  }
  addUpdateCompany(data: any) {
    return this._httpClient.post<any>(`${environment.apiUrl}/Company/AddUpdateCompany`, data);
  }

  deleteCompany(companyId: any) {
    return this._httpClient.delete<any>(`${environment.apiUrl}/Company/DeleteCompany?companyId=` + companyId);
  }

  getAllCompanies(data) {
    return this._httpClient.post<any>(`${environment.apiUrl}/Company/GetAllCompanies`, data);
  }

  GetStatistics(companyId, userId) {
    return this._httpClient.get<any>(`${environment.apiUrl}/Company/GetStatistics?companyId=${companyId}&userId=${userId}`)
  }

}
