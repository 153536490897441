export class CardStatisticsData {
  public static data = {
    ordersChart: {
      series: [
        {
          name: '2020',
          data: [45, 85, 65, 45, 65]
        }
      ],
      analyticsData: {
        orders: '2,76k'
      }
    },
    profitChart: {
      series: [
        {
          data: [0, 20, 5, 30, 15, 45]
        }
      ],
      analyticsData: {
        profit: '6,24k'
      }
    },
    statistics: {
      analyticsData: {
        sales: '230k',
        customers: '8.549k',
        products: '1.423k',
        revenue: '$9745'
      }
    },
    views: {
      analyticsData: {
        views: '36.9k'
      }
    },
    comments: {
      analyticsData: {
        comments: '12k'
      }
    },
    orders: {
      analyticsData: {
        orders: '97.8k'
      }
    },
    bookmarks: {
      analyticsData: {
        bookmarks: '26.8'
      }
    },
    reviews: {
      analyticsData: {
        reviews: '689'
      }
    },
    returns: {
      analyticsData: {
        returns: '2.1k'
      }
    },
    cpuUsage: {
      analyticsData: {
        cpuUsage: '86%'
      }
    },
    memoryUsage: {
      analyticsData: {
        memoryUsage: '1.2gb'
      }
    },
    downtimeRation: {
      analyticsData: {
        downtimeRation: '0.1%'
      }
    },
    issuesFound: {
      analyticsData: {
        issuesFound: '13'
      }
    },
    subscribers_gained: {
      series: [
        {
          name: 'Subscribers',
          data: [28, 40, 36, 52, 38, 60, 55]
        }
      ],
      analyticsData: {
        subscribers: '92.6k'
      }
    },
    revenueGenerated: {
      series: [
        {
          name: 'Revenue',
          data: [350, 275, 400, 300, 350, 300, 450]
        }
      ],
      analyticsData: {
        revenue: '97.5k'
      }
    },
    quarterlySales: {
      series: [
        {
          name: 'Sales',
          data: [10, 15, 7, 12, 3, 16]
        }
      ],
      analyticsData: {
        sales: '36%'
      }
    },
    ordersRecevied: {
      series: [
        {
          name: 'Orders',
          data: [10, 15, 8, 15, 7, 12, 8]
        }
      ],
      analyticsData: {
        orders: '97.5k'
      }
    },
    siteTraffic: {
      series: [
        {
          name: 'Traffic Rate',
          data: [150, 200, 125, 225, 200, 250]
        }
      ],
      analyticsData: {
        siteTraffic: '78.9k'
      }
    },
    activeUsers: {
      series: [
        {
          name: 'Active Users',
          data: [750, 1000, 900, 1250, 1000, 1200, 1100]
        }
      ],
      analyticsData: {
        activeUsers: '659.8k'
      }
    },
    newsletter: {
      series: [
        {
          name: 'Newsletter',
          data: [365, 390, 365, 400, 375, 400]
        }
      ],
      analyticsData: {
        newsletter: '28.7k'
      }
    }
  };
}
