import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class UserCompanyService {

    constructor(private _httpClient: HttpClient) { }

    getCompanies()
    {
      return this._httpClient.get<any>(`${environment.apiUrl}/Company/GetCompanies`)
    }

    getUserById(userId) {
        return this._httpClient.get<any>(`${environment.apiUrl}/UserCompany/getUserById?userId=` + userId);
    }

    saveData(data: any) {
        const requestData = data

        // return this._httpClient.post(this.apiUrl, requestData);
        return this._httpClient.post<any>(`${environment.apiUrl}/UserCompany/AddUpdateUserCompany`, requestData);
    }

    getUserCompany() {
        return this._httpClient.get<any>(`${environment.apiUrl}/UserCompany/UserCompany`)
    }
    // getCompanyBYId(data:any):Observable<any>{
    //     const requestData=data
    //     return this._httpClient.post(`${environment.apiUrl}/ashdajksd`,requestData)
    // }

    get(url: string) {
        return this._httpClient.get<any>(`${environment.apiUrl}/${url}`);
    }

    getCompaniesByUserId(userId: any) {
        return this._httpClient.get<any>(`${environment.apiUrl}/Company/GetCompaniesAgainstUser?userId=` + userId);
    }

    getCompaniesByCompanyAdminUserId(userId: any) {
        return this._httpClient.get<any>(`${environment.apiUrl}/Company/GetCompaniesAgainstCompanyAdminUser?userId=` + userId);
    }

}
