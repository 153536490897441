import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
import { UserCompanyService } from '../usercompany.service';
import * as SweetSnippet from 'app/main/extensions/sweet-alerts/sweet-alerts.snippetcode';
import * as LoaderSnippet from 'app/main/extensions/blockui/blockui.snippetcode';

@Component({
  selector: 'app-user-company-list',
  templateUrl: './user-company-list.component.html',
  styleUrls: ['./user-company-list.component.scss']
})
export class UserCompanyListComponent implements OnInit {

  public _snippetCodeBasic = SweetSnippet.snippetCodeBasic;
  public _snippetCodePosition = SweetSnippet.snippetCodePosition;
  public _snippetCodeAnimations = SweetSnippet.snippetCodeAnimations;
  public _snippetCodeTypes = SweetSnippet.snippetCodeTypes;
  public _snippetCodeCustomImage = SweetSnippet.snippetCodeCustomImage;
  public _snippetCodeAutoClose = SweetSnippet.snippetCodeAutoClose;
  public _snippetCodeQuestion = SweetSnippet.snippetCodeQuestion;
  public _snippetCodeAjax = SweetSnippet.snippetCodeAjax;
  public _snippetCodeConfirmText = SweetSnippet.snippetCodeConfirmText;
  public _snippetCodeConfirmColor = SweetSnippet.snippetCodeConfirmColor;
  public _snippetCodePageBlocking = LoaderSnippet.snippetCodePageBlocking;



  @BlockUI() blockUI: NgBlockUI;

  

  public data: any;
  public categories: any = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  filter={categoryId:undefined,text:''}
  public rows;
  public previousprojectFilter = '';
  private tempData = [];
  public searchValue = '';
  public tempFilterData;
  public previousStatusFilter = '';


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _UserCompanyService: UserCompanyService,
    
    ) {
  }

  ngOnInit(): void {
    // this.getData()
  }

  // Loader(){
  //   this.blockUI.start('Loading...'); // Start blocking

  //   // setTimeout(() => {
  //   //   this.blockUI.stop(); // Stop blocking
  //   // }, 2000);
  // }

  // getData(){
  //   this.blockUI.start();
  //   this._categoryService.getCategories().subscribe(response => {
  //     this.blockUI.stop();
  //     if(response.isSuccess == true)
  //     {
  //       this.categories = response.data.map(x=>{return {categoryName: x.categoryName, id: x.categoryId}})
  //       this.data = response.data;
  //       this.rows = this.data;
  //       this.tempData = this.rows;
  //       this.tempFilterData = this.rows
  //     }
  //   },(err)=>{
  //     alert("Something went wrong, please try again!")
  //     this.blockUI.stop();
  //   });
  // }

  /**
   * Filter By Projects
   *
   */
  // filterData() {
  //   this.rows = this.tempFilterData.filter(x=> {
  //     return(!this.filter.categoryId || 
  //       x.categoryId==this.filter.categoryId) && (!this.filter.text || 
  //       (x.categoryName.toLowerCase().includes(this.filter.text.toLowerCase())))
  //   });
  // }

  // removeCategory(categoryId) {
  //   this.blockUI.start();
  //   this._categoryService.deleteCategory(categoryId).subscribe((res) => {
  //     var index = this.rows.findIndex(x => x.categorytId==categoryId);
  //     if (index !== -1) {
  //       this.rows.splice(index, 1);
  //     }
  //     if (res.isSuccess === true) {
  //       this.blockUI.stop();
  //     }
      
  //   })
  // }

  // ConfirmTextOpen(categoryId) {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#7367F0',
  //     cancelButtonColor: '#E42728',
  //     confirmButtonText: 'Yes, delete it!',
  //     customClass: {
  //       confirmButton: 'btn btn-primary',
  //       cancelButton: 'btn btn-danger ml-1'
  //     }
  //   }).then((result) => {
  //     if (result.value) {
  //       this.removeCategory(categoryId);
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Deleted!',
  //         text: 'Category has been deleted!',
  //         customClass: {
  //         confirmButton: 'btn btn-success'
  //         }
  //       });
  //     }
  //   });
  // }

}
