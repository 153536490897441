import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CurrencyPipe implements PipeTransform {

  // transform(value: number): string {
  
  //   // Logic to format the value as currency (you can customize this)
  //   const formattedValue = value.toFixed(2); // Example: Rounds to 2 decimal places
  //   return `${formattedValue}`; // Example: Adding Rupee symbol
  // }

  // transform(value: number, locale: string = 'en-US'): string {
  //   const formattedValue = value.toLocaleString(locale, {
  //     maximumFractionDigits: 2,
  //     useGrouping: true,
  //     style: 'decimal',
  //     currency: 'none'
  //   });
  //   return formattedValue;
  // }

  transform(value: number, locale: string = 'en-US'): string {
    
    const formattedValue = value.toLocaleString(locale, {
      maximumFractionDigits: 2,
      useGrouping: true
    });

    // Remove currency symbol manually
    const symbolIndex = formattedValue.indexOf(value.toLocaleString(locale, { style: 'currency' }));
    if (symbolIndex !== -1) {
      return formattedValue.slice(symbolIndex + 1);
    }

    return formattedValue;
  }


}
