import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SaleinvoiceService } from '../saleinvoice.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
import { HelperService } from 'app/helper.service';
import { FilterService } from 'app/filter.service';

@Component({
  selector: 'app-sale-invoice-add',
  templateUrl: './sale-invoice-add.component.html',
  styleUrls: ['./sale-invoice-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SaleInvoiceAddComponent implements OnInit {

  // public myForm: FormGroup;
  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('addInvoiceForm', { static: false }) addInvoiceForm: NgForm;

  saleInvoice: any = {
    // saleInvoiceId: undefined,
    customerId: undefined,
    invoiceDate: null,
    dueDate: null,
    payeeReference: '',
    total: '',
    totalInclVat: '',
    vatAmount: '',
    lineItemVat: '',
    shortDescription: '',
    netAmount: 0,
    saleInvoiceContentLine: []
  };

  // saleInvoiceContentLinedto: any = {
  //   saleInvoiceId: undefined,
  //   saleInvoiceContentsLineId: undefined,
  //   lineItemProduct: '',
  //   lineItemDescription: '',
  //   lineItemQuantity: undefined,
  //   lineItemAmount: undefined,
  //   lineItemTotal: undefined,
  // }

  showError: boolean;
  selectedCompany: number;
  customerList: any;
  detail_box: boolean;
  customerName: any;
  customerAddress1: any;
  customerEmail: any;
  mobile: any;
  id: any;

  constructor(
    private saleinvoiceService: SaleinvoiceService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private filterService: FilterService
  ) { }

  async ngOnInit() {
    
    // this.saleInvoice.saleInvoiceContentLine = []
    await this.GetCustomersList();
    this.openAddForm();
    this.saleInvoice.invoiceDate ? this.saleInvoice.invoiceDate = new Date(this.saleInvoice.invoiceDate).toISOString().substr(0, 10) : this.saleInvoice.invoiceDate = new Date().toISOString().substr(0, 10);
    this.saleInvoice.dueDate ? this.saleInvoice.dueDate = new Date(this.saleInvoice.dueDate).toISOString().substr(0, 10) : this.saleInvoice.dueDate = new Date().toISOString().substr(0, 10);
    this.filterService.selectedCompany.subscribe(obj => {
      if (this.router.url == "/saleinvoiceadd") {
        this.GetCustomersList();
      }
    });
    // this.initializeForm();
  }


  // initializeForm() {
  //   this.myForm = this._formBuilder.group({
  //     CustomerId: ['', Validators.required],
  //     InvoiceNo: ['',],
  //     InvoiceDate: ['', Validators.required],
  //     DueDate: ['', Validators.required],
  //     PayeeReference: ['', Validators.required],
  //     Description: ['', Validators.required],
  //   });
  // }



  openAddForm() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.GetSaleInvoiceById(this.id);
    }
  }


  GetSaleInvoiceById(id) {
    
    this.blockUI.start();
    this.saleinvoiceService.GetSaleInvoiceById(id).subscribe(res => {
      this.blockUI.stop();
      if (res.isSuccess) {

        this.saleInvoice = res.data;
        this.onChange(res.data.customerId);

        if (this.saleInvoice.invoiceDate || this.saleInvoice.dueDate) {
          this.saleInvoice.invoiceDate = new Date(this.saleInvoice.invoiceDate).toISOString().substr(0, 10);
          this.saleInvoice.dueDate = new Date(this.saleInvoice.dueDate).toISOString().substr(0, 10);
        }

        if (res.data?.saleInvoiceContentLine) {
          this.saleInvoice.saleInvoiceContentLine = res.data.saleInvoiceContentLine;

          // this.saleInvoice.saleInvoiceContentLine.map((x: any) => {
          //   x.lineItemAmount = this.helperService.currencyFormat(x.lineItemAmount);
          //   x.lineItemTotal = this.helperService.currencyFormat(x.lineItemTotal);
          // })
          // this.saleInvoice.map((x: any) => {
          //   x.total = this.helperService.currencyFormat(x.total);
          //   x.totalIncGST = this.helperService.currencyFormat(x.totalIncGST);
          // })

        }
      }
    })
  }

  async GetCustomersList() {
    this.selectedCompany = Number(localStorage.getItem('selectedCompany'));
    if (this.selectedCompany > 0) {
      this.blockUI.start();
      const obj = await this.saleinvoiceService.GetCustomersList(this.selectedCompany).toPromise();
      this.blockUI.stop();
      if (obj.isSuccess) {
        this.customerList = obj.data;
      }
    }
  }

  async onChange(event) {

    var selectedCustomerId = event?.customerId;
    // var selectedCustomerId = event?.target?.value;
    if (selectedCustomerId) this.saleInvoice.customerId = selectedCustomerId;
    if (this.customerList?.length > 0) {

      var obj = this.customerList.find(x => x.customerId === (Number(selectedCustomerId) ? Number(selectedCustomerId) : Number(event)));

      this.customerName = obj?.customerName;
      this.customerAddress1 = obj?.customerAddress1;
      this.customerEmail = obj?.customerEmail;
      this.mobile = obj?.customerMobile;

    }
    if (this.customerName !== '' && this.customerName !== undefined || this.customerAddress1 !== '' && this.customerAddress1 !== undefined || this.customerEmail !== '' && this.customerEmail !== undefined || this.mobile !== '' && this.mobile !== undefined) {
      this.detail_box = true;
    } else {
      this.detail_box = false;
    }
  }


  calculateSubtotal(gst?: number) {
    
    this.saleInvoice.totalIncGST = 0;
    this.saleInvoice.saleInvoiceContentLine.lineItemTotal = 0;
    this.saleInvoice.total = 0;
    this.saleInvoice.gst = gst || 0;
    this.saleInvoice.saleInvoiceContentLine.lineItemTotal = this.helperService.currencyFormat(this.helperService.parseToFloat(this.saleInvoice.saleInvoiceContentLine.lineItemQuantity) * this.helperService.parseToFloat(this.saleInvoice.saleInvoiceContentLine.lineItemAmount));
    this.saleInvoice.total = this.helperService.currencyFormat(this.helperService.parseToFloat(this.saleInvoice.saleInvoiceContentLine.lineItemTotal));
    this.saleInvoice.totalIncGST = this.helperService.currencyFormat(this.helperService.parseToFloat(this.saleInvoice.total) + (this.helperService.parseToFloat(this.saleInvoice.total) * (this.saleInvoice.gst / 100)));
  }


  async onSubmit(form) {

    if (form.valid) {
      this.blockUI.start();

      this.saleInvoice.invoiceDate = new Date(this.saleInvoice.invoiceDate).toISOString();
      this.saleInvoice.dueDate = new Date(this.saleInvoice.dueDate).toISOString();

      this.saleInvoice.saleInvoiceContentLine.lineItemTotal = this.helperService.parseToFloat(this.saleInvoice.saleInvoiceContentLine.lineItemTotal)
      this.saleInvoice.saleInvoiceContentLine.lineItemAmount = this.helperService.parseToFloat(this.saleInvoice.saleInvoiceContentLine.lineItemAmount)
      this.saleInvoice.total = this.helperService.parseToFloat(this.saleInvoice.total)
      this.saleInvoice.totalIncGST = this.helperService.parseToFloat(this.saleInvoice.totalIncGST)
      var obj = { ...this.saleInvoice }
      obj.saleInvoiceContentLine = [{ ...obj.saleInvoiceContentLine }];

      var res = await this.saleinvoiceService.addUpdateSaleInvoice(obj).toPromise()
      this.blockUI.stop()
      if (res.isSuccess === true) {

        Swal.fire({
          icon: 'success',
          title: res.message ? res.message : 'Something went wrong, please try again!',
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.backToList();
          }
        });
      }
      else {
        Swal.fire({
          icon: 'error',
          title: res.message ? res.message : 'Something went wrong, please try again!',
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-danger ml-1'
          }
        });
      }
      // },
      //  (error) => {
      //   console.log('x', error)
      //   this.blockUI.stop()
      // })

    }
  }

  backToList() {
    this.router.navigate(['/sale-List'])
  }

  confirmBackToList() {
    if (this.addInvoiceForm && this.addInvoiceForm.dirty) {
      Swal.fire({
        title: 'Changes will not be saved',
        text: 'Do you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this.backToList();
        }
      });
    } else {
      this.backToList();
    }
  }

  saveForm(): void {
    if (this.addInvoiceForm.valid) {
      this.onSubmit(this.addInvoiceForm);
    } else {
      this.addInvoiceForm.control.markAllAsTouched();
    }
  }

}