import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { SaleinvoiceModule } from 'app/salesinvoive/saleinvoice.module';

import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import { UserCompanyListComponent } from './usercompany/user-company-list/user-company-list.component';
import { UserCompanyAddComponent } from './usercompany/user-company-add/user-company-add.component';
import { OktopayComponent } from './oktopay/oktopay.component';
import { SharedService } from './auth/service/shared.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DateTimeFormatPipe } from './date-time-format.pipe';
import { CurrencyPipe } from './currency.pipe';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BlockUIModule } from 'ng-block-ui';
const appRoutes: Routes = [
  {
    path: 'apps',
    loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./main/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'ui',
    loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'components',
    loadChildren: () => import('./main/components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'extensions',
    loadChildren: () => import('./main/extensions/extensions.module').then(m => m.ExtensionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'company',
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'projectgroup',
    loadChildren: () => import('./projectgroup/projectgroup.module').then(m => m.ProjectgroupModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'project',
    loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supplier',
    loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chartofaccount',
    loadChildren: () => import('./chartofaccount/chartaccount.module').then(m => m.ChartAccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'usercompany',
    loadChildren: () => import('./usercompany/usercompany.module').then(m => m.UserCompanyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'job',
    loadChildren: () => import('./job/job.module').then(m => m.JobModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'oktopay',
    loadChildren: () => import('./oktopay/oktopay.module').then(m => m.OktoPayModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forms',
    loadChildren: () => import('./main/forms/forms.module').then(m => m.FormsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tables',
    loadChildren: () => import('./main/tables/tables.module').then(m => m.TablesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'charts-and-maps',
    loadChildren: () => import('./main/charts-and-maps/charts-and-maps.module').then(m => m.ChartsAndMapsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invoice',
    loadChildren: () => import('./purchaseinvoice/purchaseinvoice.module').then(m => m.PurchaseinvoiceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'category',
    loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'projectType',
    loadChildren: () => import('./projecttype/projecttype.module').then(m => m.ProjecttypeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'jobType',
    loadChildren: () => import('./jobtype/jobtype.module').then(m => m.JobtypeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supplierType',
    loadChildren: () => import('./suppliertype/suppliertype.module').then(m => m.SuppliertypeModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bank',
    loadChildren: () => import('./bank/bank.module').then(m => m.BankModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exportcsvlog',
    loadChildren: () => import('./exportcsvlog/exportcsvlog.module').then(m => m.ExportcsvlogModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'export-xero-list',
    loadChildren: () => import('./exportxerobillslist/export-xero-bills-list.module').then(m => m.ExportXeroBillsListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'import-contacts',
    loadChildren: () => import('./importcontacts/importcontacts.module').then(m => m.ImportcontactsModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [
    AppComponent,
    ContextMenuComponent,
    BasicCustomContextMenuComponent,
    AnimatedCustomContextMenuComponent,
    SubMenuCustomContextMenuComponent,
    UserCompanyListComponent,
    UserCompanyAddComponent,
    OktopayComponent,
    DateTimeFormatPipe,
    CurrencyPipe,
    // providers: [{ provide: 'urlArray', useValue: urlArray }]
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
    SaleinvoiceModule,
    CommonModule,
    SharedModule,
    NgxDropzoneModule,
    BlockUIModule.forRoot()
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    fakeBackendProvider
  ],
  entryComponents: [BasicCustomContextMenuComponent, AnimatedCustomContextMenuComponent],
  bootstrap: [AppComponent]
})
export class AppModule {

  public urlArray = [
    "/pages/authentication/",
    "/company/",
    "/category/",
    "/users/",
    "/chartofaccount/",
    "/usercompany/",
    "/projectType/",
    "/supplierType/",
    "/supplier/",
    "/customer/",
    "/project/",
    "/projectgroup/",
    "/job/",
    "/invoice/",
    "/sale-List",
    "/bank/",
    "/payment/",
    "/jobType/",
    "saleinvoiceadd",
    "/oktopay/",
    "/paymentTypeList/",
    "exportcsvlog",
    "export-xero-list",
    "import-contacts",
  ]

  public setupUrlArray = [
    // "/",
    "/pages/authentication/login-v2/",
    "/pages/authentication/",
    "/company/",
    "/category/",
    "/users/",
    "/chartofaccount/",
    "/usercompany/",
    "/projectType/",
    "/supplierType/",
    "/jobType/",
    "/payment/",
  ]

  constructor(
    private router: Router,

    // Adeel - 17Apr23 ... added below code to hide/show company dropdown on specific routes
    private _sharedService: SharedService
  ) {
    // router.events.subscribe(
    //   (event) => {
    //     if (event instanceof NavigationStart) {
    //       // hide company dropdown
    //       if (this.urlArray.find(b => event.url.indexOf(b) != -1)) {
    //         this._sharedService.toggleVisibility(false);
    //       }
    //       else {
    //         this._sharedService.toggleVisibility(true);
    //       }
    //     }
    //   });
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        
        const url = event.url;
        const shouldShowOthers = this.urlArray.find((b) => url.indexOf(b) !== -1);
        const shouldShowSetup = this.setupUrlArray.find((su) => url.indexOf(su) !== -1);
        const isAdmin = JSON.parse(localStorage.getItem("currentUser"))?.data?.isAdmin
        
        // hide company dropdown all screen except dashboard
        if (shouldShowOthers) {
          this._sharedService.toggleVisibility(false);
        } else {
          this._sharedService.toggleVisibility(true);
        }
        
        //hide company label only setup screens

        // else if(url.includes("dashboard") && !isAdmin)
        // {
        //   this._sharedService.labelVisibility(false);

        // }



        if ((shouldShowSetup || url == "/") && (isAdmin || url.includes('/users/') ||url.includes('/usercompany/'))) {
          this._sharedService.labelVisibility(false);
        } else {
          this._sharedService.labelVisibility(true);
        }

        if (url.includes("dashboard") || url == "/") {
          this._sharedService.labelVisibility(false);
        }

      }
    });


  }
}
