<div class="modal_note">
    <div class="modal-header">
        <h4 class="modal-title">{{noteTitle}}</h4>
    </div>
    <textarea class="note_textarea" [(ngModel)]="note" rows="6" placeholder="Enter your text here"></textarea>


    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="note === '' || note === undefined"
            (click)="saveNote();">Save</button>
    </div>
    <block-ui> </block-ui>
</div>