import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseInvoiceService } from '../purchaseinvoice.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HelperService } from 'app/helper.service';

@Component({
  selector: 'app-note-history-modal',
  templateUrl: './note-history-modal.component.html',
  styleUrls: ['./note-history-modal.component.scss']
})
export class NoteHistoryModalComponent implements OnInit {
  noteList: any;
  note: string;
  @BlockUI() blockUI: NgBlockUI;
  noteCurrentDate: Date = new Date();


  constructor(public activeModal: NgbActiveModal,
    private purchaseInvoiceService: PurchaseInvoiceService,
    private router: Router,
    private helper: HelperService
  ) {
  }

  @Input() purchaseInvoiceId: number;
  @Input() currentStatus: string;
  @Input() holdModalLabel: boolean;
  noteTitle: string;

  ngOnInit(): void {
    console.log('currentStatus =>', this.currentStatus)
    this.noteTitle = this.holdModalLabel ? 'Please provide reason for holding the invoice' : 'Add Note'

  }


  saveNote() {
    if (this.note !== undefined && this.note !== '' && this.purchaseInvoiceId > 0 && !this.holdModalLabel) {
      this.blockUI.start();
      this.purchaseInvoiceService.InsertNote(this.note, this.purchaseInvoiceId, this.noteCurrentDate).subscribe(res => {
        this.blockUI.stop();
        if (res.isSuccess) {
          this.activeModal.close('success');
          Swal.fire({
            icon: 'success',
            title: res.message ? res.message : 'Something went wrong, please try again!',
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            }
          });
        }
      })
    } else if (this.note !== undefined && this.note !== '' && this.purchaseInvoiceId > 0 && this.holdModalLabel) {
      const result = Swal.fire({
        title: 'The invoice status will change to hold. Would you like to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      });

      result.then((response) => {
        if (response.isConfirmed) {
          this.blockUI.start();
          this.purchaseInvoiceService.UpdateInoviceStatus(this.purchaseInvoiceId, 4, this.note, this.noteCurrentDate).subscribe(resp => {
            this.blockUI.stop();
            if (resp.isSuccess) {
              this.helper.showMessage('Success', 'Reason saved and status updated!', 'success');
              this.activeModal.close('success');
              this.router.navigate(['/invoice/invoice-list']);
            }
          }, err => {
            this.blockUI.stop();
            this.helper.showMessage('Error', err.message, 'error');
          });
        }
      });

    }
  }
}