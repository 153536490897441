import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'app/company/company.service';
import { UsersService } from 'app/users/users.service';
import Swal from 'sweetalert2';
import { UserCompanyService } from '../usercompany.service';
import { RoleService } from 'app/auth/service/roleservice';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-user-company-add',
  templateUrl: './user-company-add.component.html',
  styleUrls: ['./user-company-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserCompanyAddComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  userCompanyObj: any = {
    companyId: 0,
    userId: 0,
  };

  public myForm: FormGroup;
  lineItems: any = [{
    userId: null,
    id: null,
    companyId: null

  }];
  public companyList: any = [];
  public allCompanies: any = [];

  uId: any;
  userObj: any;
  userList: any;
  insertUserCompanydto: any;
  roleList: any = [];
  user_dropdown = false;
  selectedCompanies: number[][] = []; // Array to hold selected companies for each row

  constructor(
    private router: Router,
    private _UserCompanyService: UserCompanyService,
    private _formBuilder: FormBuilder,
    private _CompanyService: CompanyService,
    private _ActivatedRoute: ActivatedRoute,
    private _UsersService: UsersService,
    private _RoleService: RoleService,
  ) { }

  ngOnInit(): void {
    this.getUserIdFromUserList();
    this.getRoleList();
    this.getUserList();
    this.getCompanyList();


    this.myForm = this._formBuilder.group({
      id: [0],
      companyId: [null, [Validators.required]],
      userId: [null, []],
      roleId: [null, []],

    });
  }


  getCompaniesForEachIndex(isNewAdded = false) {

    const length = this.lineItems.length;
    if (length > 0) {
      if (isNewAdded && length != 1) {
        const companyIds = this.lineItems.map(item => parseInt(item.companyId)).filter(x => x != undefined);
        this.companyList[length - 1] = this.allCompanies.filter(x => !companyIds.includes(x.companyId))
      }
      else {
        this.lineItems.forEach((element, index) => {

          if (element.companyId) {

            const companyIds = this.lineItems.filter(x => x.companyId != element?.companyId).map(item => parseInt(item.companyId));
            this.companyList[index] = this.allCompanies.filter(x => !companyIds.includes(x.companyId))

          }
          else {
            const companyIds = this.lineItems.map(item => parseInt(item.companyId));
            this.companyList[index] = this.allCompanies.filter(x => !companyIds.includes(x.companyId))
            if (!this.lineItems[index].companyId) {
              this.lineItems[index].companyId = null

            }
          }
        });
      }

    }
    this.blockUI.stop()


  }

  onSelectCompany(companyId: any, index: number) {
    this.selectedCompanies[index] = [parseInt(companyId)]; // Update selected companies array for the current row
    this.getCompaniesForEachIndex()
  }

  onSubmit() {
    this.myForm.controls['userId'].setValue(this.userObj.userId);
    this.lineItems?.forEach((element: any) => {
      element['userId'] = this.userObj.userId,
        element['companyId'] = Number(element.companyId),
        element['id'] = element.id > 0 ? element.id : 0


    });


    this.blockUI.start()
    this._UserCompanyService.saveData(this.lineItems).subscribe((res) => {
      this.blockUI.stop()
      if (res.isSuccess === true) {
        Swal.fire({
          icon: 'success',
          title: res.message ? res.message : 'User Company Saved',
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
        });
        this.router.navigate(['//users/usersList'])
      }
      else {
        Swal.fire({
          icon: 'error',
          title: res.message ? res.message : 'Something went wrong, please try again!',
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-danger ml-1'
          }
        });
      }
    })
  }

  getUserIdFromUserList() {
    this.uId = this._ActivatedRoute.snapshot.paramMap.get('id');
    const user = JSON.parse(localStorage.getItem("currentUser"))?.data
    if (this.uId) {
      this.getUserById(this.uId);
      user?.isAdmin == true ? this.getCompaniesByUserId(this.uId) : this.getSpecififcCompaniesByUserId(this.uId, user?.userId);
    }
  }

  getUserById(userId) {
    this._UserCompanyService.getUserById(userId).subscribe(obj => {
      if (obj.isSuccess == true) {
        this.userObj = obj.data;
      }
    })
  }

  getCompaniesByUserId(userId) {
    this._UserCompanyService.get(`UserCompany/getCompaniesByUserId?userId=${userId}`).subscribe(obj => {
      if (obj.isSuccess == true) {
        this.lineItems = obj.data;
        if (this.lineItems.length !== 0) {
          this.user_dropdown = true;
        } else {
          this.user_dropdown = false;
        }
        // console.log(this.lineItems,'items')
      }
    })
  }


  getSpecififcCompaniesByUserId(userId, loggedInUserId) {
    this._UserCompanyService.get(`UserCompany/getSpecificCompaniesByUserId?userId=${userId}&&loggedInUserId=${loggedInUserId}`).subscribe(obj => {
      if (obj.isSuccess == true) {
        this.lineItems = obj.data;
        if (this.lineItems.length !== 0) {
          this.user_dropdown = true;
        } else {
          this.user_dropdown = false;
        }
        // console.log(this.lineItems,'items')
      }
    })
  }

  filterCompanyList(companies: any[], selected: number[]): any[] {

    return companies.filter(company => !selected?.includes(company.companyId))
      .map(o => { return { companyName: o.companyName, companyId: o.companyId } });
  }



  getCompanyList() {
    this.blockUI.start()

    const user = JSON.parse(localStorage.getItem("currentUser"))?.data
    if (user?.isAdmin == true) {
      this._CompanyService.getCompanies().subscribe(
        obj => {

          this.allCompanies = obj?.data?.data
          this.getCompaniesForEachIndex()

        },
        error => {
          console.log(error); // handle the error here
          this.blockUI.stop()

        }
      )
    }
    else {
      if (user?.userId) {
        this._UserCompanyService.getCompaniesByCompanyAdminUserId(user?.userId).subscribe(
          obj => {

            // this.companyList[0] = obj?.data?.data;
            this.allCompanies = obj?.data
            this.getCompaniesForEachIndex()

          },
          error => {
            console.log(error); // handle the error here
          }
        )
      }

    }

  }


  getRoleList() {
    this._RoleService.getRoleList().subscribe(obj => {
      if (obj.isSuccess == true) {
        const isAdmin = JSON.parse(localStorage.getItem("currentUser"))?.data?.isAdmin
        this.roleList = obj.data.filter(x => !isAdmin ? x.roleId !== 2 && x.roleId !== 1 : x.roleId !== 1);
      }
    })
  }

  getUserList() {
    this._UsersService.getUsers().subscribe(obj => {
      if (obj.isSuccess == true) {
        this.userList = obj.data;
      }
    })
  }

  addLineItem() {
    this.user_dropdown = true;

    this.lineItems.push({})

    this.selectedCompanies.push([]);
    this.getCompaniesForEachIndex(true)
  }

  removeLineItem(ind) {

    // this.lineItems.forEach((element,index) => {
    //   debugger
    //   if(element.companyId)
    //     {

    //      const companyIds = this.lineItems.filter(x=>x.companyId!=element?.companyId).map(item => parseInt(item.companyId));
    //      this.companyList[index]=this.allCompanies.filter(x=>!companyIds.includes(x.companyId))

    //     }
    //     else
    //     {
    //       const companyIds = this.lineItems.map(item => parseInt(item.companyId));
    //       this.companyList[index]=this.allCompanies.filter(x=>!companyIds.includes(x.companyId))
    //     }
    // });

    const companyId = this.lineItems[ind].companyId
    const company = this.allCompanies.filter(x => x.companyId == companyId)
    this.lineItems.splice(ind, 1)
    this.selectedCompanies.splice(ind, 1);
    this.companyList.splice(ind, 1);
    if (company) {
      this.lineItems.forEach((element, index) => {
        this.companyList[index].push(...company);
      });
    }


  }

  backToList() {
    this.router.navigate(['/users/usersList'])
  }
}


