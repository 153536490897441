export const locale = {
  lang: 'fr',
  data: {
    HEADER: {
      NAME: 'NOM',
      EMAIL: 'EMAIL',
      AGE: 'ÂGE',
      STATUS: 'STATUT',
      ACTIONS: 'ACTIONS'
    }
  }
};
