import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(user: { email: string; password: string }): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/Auth/authenticate`, user).pipe(map(user => {
          if (user.isSuccess == false) {
            // console.log(user.message,'user login nahi huwa=============== ')
            // this._toastrService.error(user.message);
            Swal.fire({
              icon: 'error',
              title: user.message,
              confirmButtonColor: '#7367F0',
              cancelButtonColor: '#E42728',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-danger ml-1'
              }
            });
            // alert(user.message)
            // this._toastrService.error(user.message);
            // this._toastrService.error(user.message ? user.message :
            //   'Wrong Email And Password', "",
            //   // { toastClass: 'toast ngx-toastr', closeButton: true }
            // );
            return
          }
          else {
            if (user && user.token) {

              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(user));

              // Display welcome toast!
              // setTimeout(() => {
              //   this._toastrService.success(
              //     'You have successfully logged in as an ' +
              //     user.role +
              //     ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
              //     '👋 Welcome, ' + user.data.username + '!',
              //     { toastClass: 'toast ngx-toastr', closeButton: true }
              //   );
              // }, 2500);

              // notify
              this.currentUserSubject.next(user);
            }


            return user;
          }
          // login successful if there's a jwt token in the response

        })
      );
  }


  forgetEmailSend(user: { email: string; }): Observable<any> {

    return this._http.post<any>(`${environment.apiUrl}/Auth/forgetpassword`, user).pipe(
      map(data => {
        if (data.isSuccess == true) {

          Swal.fire({
            icon: 'success',
            title: 'Reset password link has been sent to your email id',
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            }
          });
          return
        }
        else if (data.isSuccess == false) {

          Swal.fire({
            icon: 'error',
            title: 'Account not found.',
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-danger ml-1'
            }
          });
          return
        }
      })
    );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }

  addResetPassword(data: any) {
    return this._http.post<any>(`${environment.apiUrl}/Auth/ResetPassword`, data);
  }
}
