// import { Directive, ElementRef, HostListener } from '@angular/core';
// import { CurrencyPipe } from '@angular/common';

// @Directive({
//   selector: '[appCurrencyFormat]'
// })
// export class CurrencyFormatDirective {
//   private previousValue: string = '';

//   constructor(private el: ElementRef, private currencyPipe: CurrencyPipe) {}

//   @HostListener('input', ['$event.target.value'])
//   onInput(inputValue: string) {
//     // Remove any non-numeric characters
//     let numericValue = inputValue.replace(/[^0-9.]/g, '');
//     let formattedValue = this.currencyPipe.transform(numericValue, 'USD', 'symbol', '1.2-2');
    
//     // Update the input field value with the formatted value
//     if (formattedValue !== this.previousValue) {
//       this.previousValue = formattedValue;
//       this.el.nativeElement.value = formattedValue;
//       this.el.nativeElement.dispatchEvent(new Event('input'));
//     }
//   }
// }

import { Directive, HostListener, ElementRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Directive({
  selector: '[appCurrencyFormat]',
})
export class CurrencyFormatDirective {
  constructor(private el: ElementRef) {}
  
  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    const value = parseFloat(event.target.value.replace(/,/g, '')).toFixed(2);
    event.target.value = this.formatCurrency(value);
  }
  
  private formatCurrency(value: string): string {
    if(!isNaN(Number(value)))
    {
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      parts[1] = parts[1] ? parts[1].padEnd(2, '0') : '00';
      return parts.join('.');
    }
    else{
      return '';
    }
  }
  
  @HostListener('input', ['$event'])
  onInput(event: any) {
    const parts = event.target.value.toString().split('.');
    if(parts.length<=2)
    {
      parts[0] = parts[0].replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if(parts[1])
      {
        parts[1] = parts[1]?.replace(/\D/g, '');
      }
      event.target.value= parts.join('.');

      if(parts[1]?.toString().length >2)
      {
        event.target.value=this.formatCurrency(parseFloat(event.target.value.replace(/,/g, '')).toFixed(2))
      }
    }
    else{
      event.target.value=parts[0]+'.'+parts[1]
    }
  }
}
