import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  roundOffTwoDecimal(value: number): number {
    return Number(value.toFixed(2));
  }
  currencyFormat(value: any) {
    const parts = value.toString().split('.');
    if (parts.length <= 2) {
      parts[0] = parts[0].replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (parts[1]) {
        parts[1] = parts[1]?.replace(/\D/g, '');
      }
      value = parts.join('.');

      if (parts[1]?.toString().length > 2) {
        value = this.formatCurrency(parseFloat(value.replace(/,/g, '')).toFixed(2))
      }
    }
    else {
      value = parts[0] + '.' + parts[1];
    }
    return value;
  }

  private formatCurrency(value: string): string {
    if (!isNaN(Number(value))) {
      const parts = value.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      parts[1] = parts[1] ? parts[1].padEnd(2, '0') : '00';
      return parts.join('.');
    }
    else {
      return '';
    }
  }
  parseToFloat(value: any): number {
    if (typeof (value) == 'string') {
      return Number(parseFloat(value.replace(/,/g, '')).toFixed(2));
    }
    else {
      return value;
    }
  }

  showMessage(title, html, type) {
    return Swal.fire(title, html ? html : 'Something went wrong, please try again!', type);
  }


  confirm(title = "Confirm", html, type) {
    return new Promise((resolve) => {
      Swal.fire({
        title: title,
        html: html,
        icon: type, // Customizing the type of icon (info, success, warning, error, question)
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false);
        }
      });
    });
  }



}
