import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';
import { User } from 'app/auth/models';
import { coreConfig } from 'app/app-config';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'app/users/users.service';
import { UserCompanyService } from 'app/usercompany/usercompany.service';
import { FilterService } from 'app/filter.service';
import { SharedService } from 'app/auth/service/shared.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {

  selectedCompany: number;
  selectedCompanyName: '';
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  public isTransactionScreen: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  @HostBinding('class.fixed-top')
  public isFixed = false;
  public showDrp = false

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;
  companyData: { [x: number]: ""; };

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */

  showNavBar$;
  showCompanyLabel$;

  public companyList: any = [];
  public selectedStatus = [];

  constructor(
    private _SharedService: SharedService,
    private filterService: FilterService,
    private route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    public _userService: UsersService,
    private _UserCompanyService: UserCompanyService,
  ) {

    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      fr: {
        title: 'French',
        flag: 'fr'
      },
      de: {
        title: 'German',
        flag: 'de'
      },
      pt: {
        title: 'Portuguese',
        flag: 'pt'
      }
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key)
    : void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  logout() {
    localStorage.clear()
    this._authenticationService.logout();
    this._router.navigate(['/pages/authentication/login-v2']);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.showNavBar$ = this._SharedService.isCompanyListVisible$
    this.showCompanyLabel$ = this._SharedService.isCompanyLabelVisible$
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.filterService.selectedCompany.subscribe(companyId => {
      this.selectedCompany = companyId;
    });

    this.selectedCompany = Number(localStorage.getItem('selectedCompany'))
    this.selectedCompanyName = JSON.parse(localStorage.getItem('selectedCompanyName'))
    this.getCompaniesByUserId(Number(this.currentUser?.data?.userId))

    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });
  }

  isShow() {

    console.log(this._router.url);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  getCompaniesByUserId(userId) {
    this._UserCompanyService.get(`Company/GetCompaniesAgainstUser?userId=${userId}`).subscribe(obj => {
      this.companyList = obj.data;
      if (!this.selectedCompany) {
        this.selectedCompany = this.companyList[0]?.companyId;
        this.selectedCompanyName = this.companyList[0]?.companyName;
        localStorage.setItem('selectedCompany', String(this.selectedCompany))
        localStorage.setItem('selectedCompanyName', JSON.stringify(this.selectedCompanyName));

        // this.companyData = {
        //   [this.selectedCompany]: this.selectedCompanyName,
        // };

      }

    })
  }
  setComapnyId($event) {
    debugger
    localStorage.setItem('selectedCompany', $event.target.value)
    // const companyData = {
    //   [localStorage.getItem('selectedCompany')]: this.selectedCompanyName,
    // };
    const companyId = $event.target.value;
    const userRole=this.companyList.find(x=>x.companyId==companyId)?.role
    const name = $event.target?.options[$event.target.selectedIndex]?.text
    localStorage.setItem('selectedCompanyName', JSON.stringify(name));
    localStorage.setItem('userRole', userRole);
    this.selectedCompanyName = JSON.parse(localStorage.getItem('selectedCompanyName'))
    this.filterService.setSelectedCompany(companyId);
    const isAdmin: boolean = JSON.parse(localStorage.currentUser)?.data?.isAdmin;
    if(!isAdmin)
      {
        this._SharedService.RefreshMenu()

      }

  }

  // showLabelForUrlArray(): boolean {
  //   const currentUrl = this._router.url;

  //   // Check if the current URL is present in the urlArray
  //   const isUrlInArray = this.appmo .some(url => currentUrl.includes(url));

  //   return isUrlInArray;
  // }


  ngDocheck() {
    let value = localStorage.getItem('showCompanyDrp');
    if (value === "true") {
      this.showDrp = true
    } else {
      this.showDrp = false
    }
  }


  toggleDropdown(val) {
    this._SharedService.toggleVisibility(val);
  }

  toggleLabel(val) {
    this._SharedService.labelVisibility(val);
  }


}
