import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Role } from '../models';

@Injectable({ providedIn: 'root' })
export class RoleService {

    constructor(private _http: HttpClient) { }


    getRoleList() {
        return this._http.get<any>(`${environment.apiUrl}/Role/GetRole`);
    }


}
