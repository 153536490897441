import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class SharedService {

    constructor(private _http: HttpClient) { }


    isCompanyListVisible = new BehaviorSubject<boolean>(true);
    isCompanyListVisible$ = this.isCompanyListVisible.asObservable();

    isCompanyLabelVisible = new BehaviorSubject<boolean>(false);
    isCompanyLabelVisible$ = this.isCompanyLabelVisible.asObservable();

    menuRefresh = new BehaviorSubject<boolean>(true);
    menuRefresh$ = this.menuRefresh.asObservable();

    toggleVisibility(isVisible: boolean) {
        this.isCompanyListVisible.next(isVisible);
    }

    labelVisibility(isVisible: boolean) {
        this.isCompanyLabelVisible.next(isVisible);
    }

    RefreshMenu() {
        this.menuRefresh.next(!this.menuRefresh);
    }

   
}

