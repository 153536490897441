import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  // transform(value: string, timeZone: string): string {
  //   const serverDateTime = new Date(value);

  //   const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'Asia/Kolkata' };
  //   return new Intl.DateTimeFormat('en-US', options).format(serverDateTime);
  // }

  transform(value: string): string {
    const serverDateTime = new Date(value);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    return new Intl.DateTimeFormat('en-US', options).format(serverDateTime);
  }

  // transform(value: string): string {

  //   if (moment(value).isValid()) {
  //     /** Method to find user’s timezone */
  //     const timezone = moment.tz.guess();

  //     /** Your format for dates, you want to display in :) */
  //     // const format = ‘MM-DD - YYYY HH: mm:ss z’;

  //     /** Returns the formatted date with expected timezone */
  //     return moment.tz(value, timezone).format(format);
  //   }
  //   return value;
  // }

}
