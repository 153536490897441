<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div>
                <form class="form w-100" #addInvoiceForm="ngForm">
                    <!-- Payment Start Here -->
                    <div class="row">
                        <div class="col-12">
                            <h2 *ngIf="!saleInvoice.saleInvoiceId" class="m-2">Add a sale invoice
                            </h2>
                            <h2 *ngIf="saleInvoice.saleInvoiceId" class="m-2">Edit a sale invoice
                            </h2>
                            <!-- <h4 class="card-title m-2">Edit a purchase invoice
                            </h4> -->
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6 ">
                                        <!-- <div class="form-group row">
                                            <label for="customerId"
                                                class="col-form-label col-md-3 col-sm-4 col-12">Customer</label>
                                            <fieldset
                                                class="form-group col-md-8 col-lg-7 col-sm-6 col-12 mb-0 px-lg-0 px-1">
                                                <ng-select (change)="onChange($event)" [items]="customerList"
                                                    [(ngModel)]="saleInvoice.customerId" name="customerId"
                                                    bindLabel="customerName" bindValue="customerId"></ng-select>
                                            </fieldset>
                                        </div> -->
                                        <style>
                                            .error-message {
                                                color: red;
                                                font-size: 12px;
                                                margin-top: 4px;
                                            }
                                        </style>

                                        <div class="form-group row">
                                            <label for="customerId"
                                                class="col-form-label col-md-3 col-sm-4 col-12">Customer</label>
                                            <fieldset
                                                class="form-group col-md-8 col-lg-7 col-sm-6 col-12 mb-0 px-lg-0 px-1">
                                                <ng-select #customerRef="ngModel"
                                                    [class.error]="customerRef.invalid && customerRef.touched"
                                                    (change)="onChange($event)" [items]="customerList"
                                                    [(ngModel)]="saleInvoice.customerId" name="customerId"
                                                    bindLabel="customerName" bindValue="customerId"
                                                    required></ng-select>
                                                <small class="form-text text-danger"
                                                    *ngIf="customerRef.invalid && customerRef.touched">This
                                                    field is required!</small>
                                            </fieldset>
                                        </div>


                                        <div *ngIf="detail_box" class="form-group row">
                                            <label class="col-form-label col-md-3 col-sm-4 col-12"></label>
                                            <div
                                                class="col-md-8 col-lg-7 col-sm-6 py-1 pl-1 mx-sm-0 mx-1  mt-md-1 text-left border border-3 rounded bg-light ">
                                                <label class=" font-weight-bold h6 mb-0 ">Address </label> <br><label
                                                    class="mb-0 ">{{customerAddress1}}
                                                </label><br><br>
                                                <label
                                                    class=" font-weight-bold h6 mb-0 ">Name:</label>&nbsp;&nbsp;&nbsp;&nbsp;<label
                                                    class="mb-0 ">{{customerName}} </label><br>
                                                <label class=" font-weight-bold h6 mb-0 ">Email:</label>
                                                &nbsp;&nbsp;&nbsp;&nbsp;<label
                                                    class="mb-0 ">{{customerEmail}}</label><br>
                                                <label
                                                    class=" font-weight-bold h6 mb-0 ">Mobile:</label>&nbsp;&nbsp;&nbsp;<label
                                                    class="mb-0 ">{{mobile}} </label><br>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 ">
                                        <!-- <div class="form-group row ">
                                            <label for="InvoiceNo"
                                                class="col-sm-4 col-md-5 col-form-label ">Invoice#</label>
                                            <input type="text" class="form-control" [(ngModel)]="saleInvoice.InvoiceNo" name="InvoiceNo">
                                        </div> -->

                                        <div class="form-group row">
                                            <label for="invoiceDate" class="col-sm-4 col-md-5 col-form-label">Invoice
                                                date</label>
                                            <div class="col-sm-6 col-md-7">
                                                <input #invoiceRef="ngModel" type="date" class="form-control"
                                                    [class.error]="invoiceRef.invalid && invoiceRef.touched"
                                                    type="date" class="form-control"
                                                    [(ngModel)]="saleInvoice.invoiceDate" name="invoiceDate" required>
                                                <small class="form-text text-danger"
                                                    *ngIf="invoiceRef.invalid && invoiceRef.touched">This
                                                    field is required!</small>
                                            </div>
                                        </div>


                                        <div class="form-group row ">
                                            <label for="dueDate" class="col-sm-4 col-md-5 col-form-label ">Due
                                                date</label>
                                            <div class="col-sm-6 col-md-7 ">
                                                <input #duedateRef="ngModel" type="date" class="form-control"
                                                    [class.error]="duedateRef.invalid && duedateRef.touched"
                                                    [(ngModel)]="saleInvoice.dueDate" name="dueDate" required>
                                                <small class="form-text text-danger"
                                                    *ngIf="duedateRef.invalid && duedateRef.touched">This
                                                    field is required!</small>
                                            </div>

                                        </div>

                                        <div class="form-group row ">
                                            <label for="payeeReference" class="col-sm-4 col-md-5 col-form-label ">Payee
                                                reference</label>
                                            <div class="col-sm-6 col-md-7 ">
                                                <input [(ngModel)]="saleInvoice.payeeReference" name="payeeReference"
                                                    type="text" class="form-control">
                                                <!-- <small class="form-text text-danger"
                                                    *ngIf="payeeRef.invalid && customerRef.touched">This
                                                    field is required!</small> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Payment Start Finish -->
                    <hr>


                    <div class="col-12 ">

                        <div class="card-body pb-0 ">
                            <div class="row project_div mb-1 ">
                                <div class="col-lg-3 px-lg-0 ">
                                    <label for="lineItemProduct " class="col-form-label d-flex ">Item</label>
                                    <input #itemRef="ngModel"
                                        [class.error]="itemRef.invalid && itemRef.touched"
                                        [(ngModel)]="saleInvoice.saleInvoiceContentLine.lineItemProduct" name="lineItemProduct"
                                        class="form-control small" required />
                                    <small class="form-text text-danger"
                                        *ngIf="itemRef.invalid && itemRef.touched">This
                                        field is required!</small>
                                </div>

                                <div class="col-lg-4 pr-lg-0 ">
                                    <label for="saleInvoiceContentLine.lineItemDescription "
                                        class="col-form-label d-flex ">Description</label>
                                    <input #descRef="ngModel"
                                        [class.error]="descRef.invalid && descRef.touched"
                                        [(ngModel)]="saleInvoice.saleInvoiceContentLine.lineItemDescription"
                                        name="lineItemDescription" class="form-control small" required />
                                    <small class="form-text text-danger"
                                        *ngIf="descRef.invalid && descRef.touched">This
                                        field is required!</small>
                                </div>

                                <div class="col-lg-1 pr-lg-0 ">
                                    <label for="saleInvoiceContentLinedto " class="col-form-label  d-flex ">Qty</label>
                                    <input #qtyRef="ngModel" [class.error]="qtyRef.invalid && qtyRef.touched"
                                        (change)="calculateSubtotal()"
                                        [(ngModel)]="saleInvoice.saleInvoiceContentLine.lineItemQuantity" name="lineItemQuantity"
                                        type="number" class="form-control small " min="0" required />
                                    <small class="form-text text-danger"
                                        *ngIf="qtyRef.invalid && qtyRef.touched">This
                                        field is required!</small>
                                </div>

                                <div class="col-lg-2 pr-lg-0 ">
                                    <label for="lineItemAmount "
                                        class="col-form-label  d-flex justify-content-start justify-content-start justify-content-lg-end ">Amount</label>
                                    <input #amountRef="ngModel"
                                        [class.error]="amountRef.invalid && amountRef.touched"
                                        (change)="calculateSubtotal()"
                                        [(ngModel)]="saleInvoice.saleInvoiceContentLine.lineItemAmount" name="lineItemAmount"
                                        class="form-control text-end " type="text"
                                        class="form-control text-right small min=" required appCurrencyFormat />
                                    <small class="form-text text-danger"
                                        *ngIf="amountRef.invalid && amountRef.touched">This
                                        field is required!</small>
                                </div>
                                <div class="col-lg-2 pr-lg-0 ">
                                    <label for="lineItemAmount "
                                        class="col-form-label  d-flex justify-content-start justify-content-lg-end ">Total</label>
                                    <input [(ngModel)]="saleInvoice.saleInvoiceContentLine.lineItemTotal" name="lineItemTotal"
                                        class="form-control text-end " type="text"
                                        class="form-control text-right small min=" disabled appCurrencyFormat />
                                    <!-- <small class="form-text text-danger ">This
                                                field is required and can not be negative!</small> -->
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- start notes part -->
                    <div class="row ">
                        <div class="col-12 ">
                            <div class="card-body pt-0 ">
                                <div class="row ">
                                    <div class="col-md-6 ">
                                        <div class="form-group ">
                                            <label for="description " class="col-form-label ">Note</label>
                                            <textarea [(ngModel)]="saleInvoice.description" style="resize: none "
                                                name="description" class="form-control " rows="5 "></textarea>
                                        </div>
                                    </div>

                                    <div class="col-md-6 ">
                                        <div class="form-group row d-flex justify-content-md-end ">
                                            <label for="total " class="col-sm-4 col-md-3 col-form-label ">Sub-Total
                                            </label>
                                            <div class="col-lg-3 col-md-6 ">
                                                <input [(ngModel)]="saleInvoice.total" type="text"
                                                    class="form-control small text-right " name="total" disabled appCurrencyFormat>
                                            </div>
                                        </div>

                                        <div class="form-group row d-flex justify-content-md-end ">
                                            <label for="gst " class="col-sm-4 col-md-3 col-form-label ">GST
                                            </label>
                                            <div class="col-lg-3 col-md-6 ">
                                                <input (change)="calculateSubtotal(saleInvoice.gst)"
                                                    [(ngModel)]="saleInvoice.gst" type="number"
                                                    class="form-control small text-right " name="gst">
                                            </div>
                                        </div>

                                        <div class="form-group row d-flex justify-content-md-end ">
                                            <label for="totalIncGST"
                                                class="col-sm-4 col-md-3 col-form-label ">Total</label>
                                            <div class="col-lg-3 col-md-6 ">
                                                <input readonly [(ngModel)]="saleInvoice.totalIncGST" type="text"
                                                    class="form-control small text-right " name="totalIncGST" appCurrencyFormat>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-12 ">
                            <div class="card-body ">
                                <div class="row ">
                                    <div class="col-12 ">
                                        <div class=" form-group row d-flex justify-content-end ">
                                            <button (click)="confirmBackToList()" rippleEffect
                                                class="col-lg-1 col-sm-2 col-4 btn btn-danger mr-1 ">Back</button>
                                            <button (click)="saveForm()" type="button" rippleEffect
                                                class="col-lg-1 col-sm-2 col-4 btn btn-primary mr-1 ">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <block-ui> </block-ui>
</div>