export const locale = {
  lang: 'pt',
  data: {
    HEADER: {
      NAME: 'NOME',
      EMAIL: 'O EMAIL',
      AGE: 'ERA',
      STATUS: 'STATUS',
      ACTIONS: 'AÇÕES'
    }
  }
};
