<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <app-content-header></app-content-header>

        <!-- Responsive tables -->
        <div class="row" id="table-responsive">
            <div class="col-12">
                <div class="card">
                    <div class="m-1">
                        <div class="row">
                            <div
                                class="col-xl-9 col-lg-9 col-md-8 col-sm-6 col-12 d-flex justify-content-center align-items-center d-sm-block ">
                                <h2>Sale invoices</h2>

                            </div>
                            <div
                                class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 d-flex justify-content-center align-items-center d-sm-block mt-sm-0 mt-1">
                                <button class="btn btn-primary pull-right" routerLink="/saleinvoiceadd" rippleEffect>Add
                                    a sale invoice</button>
                            </div>
                        </div>
                        <div class=" row ">

                            <!-- <div class="col-xl-3 col-md-3 col-sm-12 col-lg-3 mt-sm-2 mt-2 ">
                                <fieldset class="form-group mb-0 ">
                                    <ng-select [items]="customerList" bindLabel="customerName"
                                        placeholder="Customer name" bindValue="customerId"></ng-select>
                                </fieldset>
                            </div> -->

                            <div class="col-xxl-5 col-lg-6 col-md-7 col-sm-12 mt-sm-2 mt-2">
                                <label class="d-flex align-items-center mb-0  ml-md-0">
                                    <input [(ngModel)]="filter.searchText" name="searchValue" type="search"
                                        class="form-control" placeholder="Enter Invoice# or reference#, customer name"
                                        (input)="filterData()" />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="container mw-100 sale_invoice">
                        <ngx-datatable #tbl_company class="bootstrap core-bootstrap pointer" [rows]="rows"
                            [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                            [scrollbarH]="true" (sort)="onSort($event)">

                            <ngx-datatable-column name="S.No" prop="seriolNo" [width]="50" [draggable]="false"
                                [sortable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="colSpan" (click)="onRowDoubleClick(row)">
                                        {{ (page - 1) * pageSize + data?.indexOf(row) + 1 }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Date" prop="invoiceDate" [draggable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="colSpan" (click)="onRowDoubleClick(row)">
                                        {{row.invoiceDate | date:'dd-MMM-yyyy'}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Invoice #" prop="invoiceNo" [draggable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="colSpan" (click)="onRowDoubleClick(row)">
                                        {{row.invoiceNo ? row.invoiceNo : '-'}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Payee Reference" prop="payeeReference" [draggable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="colSpan" (click)="onRowDoubleClick(row)">
                                        {{row.payeeReference ? row.payeeReference : '-'}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Customer" prop="customer.customerName" [draggable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="colSpan" (click)="onRowDoubleClick(row)">
                                        {{ row.customer.customerName?row.customer.customerName:'-' }}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Total" prop="totalIncGST" [draggable]="false">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="colSpan" (click)="onRowDoubleClick(row)">
                                        {{row.totalIncGSTs ? row.totalIncGSTs : '-'}}
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Actions" [sortable]="false" [draggable]="false"
                                [frozenRight]="true" cellClass="actionbtn">
                                <ng-template ngx-datatable-cell-template let-row="row" [ng] class="acctiontable">
                                    <div class="d-flex align-items-center col-actions">
                                        <a placement="left" ngbTooltip="Edit"
                                            routerLink="/saleinvoiceadd/{{ row.saleInvoiceId }}"><i data-feather="edit"
                                                class="mr-1 text-dark"></i></a>
                                        <a placement="right" ngbTooltip="Delete" [swal]="SweetAlertConfirmText"><i
                                                data-feather="trash" class="mr-1 text-dark"></i></a>
                                        <swal #SweetAlertConfirmText (open)="ConfirmTextOpen(row.saleInvoiceId)"></swal>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                        <div class="d-flex flex-column flex-sm-row justify-content-end">
                            <span class="d-flex mb-1 mb-sm-0" style="margin-top: 8px; margin-right: 10px;">Total Records
                                {{this.totalRecords ? this.totalRecords : 0 }}</span>
                            <div class="d-flex mb-1 mb-sm-0">
                                <span class="d-flex" style="margin-top: 8px;">Show</span>
                                <select class="form-control mx-1" (change)="changePageSize()"
                                    [(ngModel)]="this.filter.pageSize">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="1000">1000</option>
                                </select>
                            </div>
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="totalRecords"
                                (pageChange)="onPageChange($event)" [maxSize]="5" [boundaryLinks]="true"
                                [ellipses]="true">
                            </ngb-pagination>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--/ Responsive tables -->

    </div>
    <block-ui> </block-ui>
</div>