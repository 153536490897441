<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
  </div>

  <!-- <div class="col-3 mt-2 ml-1 ml-md-0 mt-md-0">
    <fieldset class="form-group mb-0">
      <ng-select
        [items]="selectStatus"
        [(ngModel)]="selectedStatus"
        bindLabel="name"
        placeholder="Anytime Developers"
      ></ng-select>
    </fieldset>
  </div> -->

  <div *ngIf="showNavBar$ | async" class="col-8 col-xl-3 col-lg-3 col-md-3 col-sm-5 ml-1 ml-md-0 mt-md-0">
    <div class="form-group mb-0">
      <select class="form-control" (change)="setComapnyId($event)" name="companyId"
        [(ngModel)]="selectedCompany">
        <option *ngFor="let items of companyList" value="{{items.companyId}}">{{items.companyName}}
        </option>
      </select>
    </div>
  </div>
  <div *ngIf="showCompanyLabel$ | async" class="col-8 col-xl-3 col-lg-3 col-md-3 col-sm-5 ml-1 ml-md-0 mt-md-0 d-flex align-items-center">
    <div class="form-group mb-0">
      <label style="color: white; font-size: 18px; font-weight: bold;">{{selectedCompanyName}}</label>
    </div>
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none ">
            <span class="user-name font-weight-bolder">{{ this.currentUser?.firstName }} {{ this.currentUser?.lastName
              }}</span><span class="user-status">{{ this.currentUser?.role }}</span>
          </div>
          <span class="d-none d-sm-block"
            style="margin-right: 10px;font-weight: 500;">{{this.currentUser?.data.username}}</span>
          <span class="avatar">
            <img class="round" src="assets/images/portrait/small/default.png" alt="avatar" height="40"
              width="40" /><span class="avatar-status-online"></span></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
          <div class="d-flex  align-items-center">
            <a ngbDropdownItem class="pr-1"><span [data-feather]="'user'" [class]="''"></span></a>
            <span class="text-wrap">{{this.currentUser?.data.username}}</span> 
          </div>
    
        <!-- <span class="user-name font-weight-bolder">{{this.currentUser.data.email}}</span> -->
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout </a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>