import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoiceService {

  constructor(private _httpClient: HttpClient) { }

  getPurchaseInvoice(data) {
    return this._httpClient.post<any>(`${environment.apiUrl}/PurchaseInvoice/GetPurchaseInvoice`, data);
  }

  getAllTypes() {
    return this._httpClient.get<any>(`${environment.apiUrl}/PurchaseInvoice/GetAllTypes`)
  }

  addUpdatePurchaseInvoice(data: any) {
    
    return this._httpClient.post<any>(`${environment.apiUrl}/PurchaseInvoice/AddUpdatePurchaseInvoice`, data);
  }

  getPurchaseInoviceById(purchaseInvoiceId) {
    return this._httpClient.get<any>(`${environment.apiUrl}/PurchaseInvoice/GetPurchaseInoviceById?purchaseInvoiceId=` + purchaseInvoiceId)
  }

  deletePurchaseInvoice(purchaseInvoiceId: any) {
    return this._httpClient.delete<any>(`${environment.apiUrl}/PurchaseInvoice/DeletePurchaseInvoice?purchaseInvoiceId=` + purchaseInvoiceId);
  }

  GetPurchaseInoviceWithDetailById(purchaseInvoiceId) {
    return this._httpClient.get<any>(`${environment.apiUrl}/PurchaseInvoice/GetPurchaseInoviceWithDetailById?purchaseInvoiceId=` + purchaseInvoiceId)
  }

  UpdateInoviceStatus(purchaseInvoiceId: number, selectedStatusId: number, note: string, noteCurrentDate: Date) {
    const url = `${environment.apiUrl}/PurchaseInvoice/UpdateInoviceStatus?purchaseInvoiceId=${purchaseInvoiceId}&selectedStatusId=${selectedStatusId}&note=${note}&noteCurrentDate=${encodeURIComponent(noteCurrentDate?.toISOString())}`;
    return this._httpClient.post<any>(url, {});
  }

  UpdateBulkInoviceStatus(purchaseInvoiceIds: number[], selectedStatusId: number, note: string, noteCurrentDate: Date) {
    const url = `${environment.apiUrl}/PurchaseInvoice/UpdateBulkInoviceStatus`;
    return this._httpClient.post<any>(url, {purchaseInvoiceIds , selectedStatusId , note , noteCurrentDate});
  }
  GetPendingPurchaseInvoice(companyId) {
    return this._httpClient.get<any>(`${environment.apiUrl}/PurchaseInvoice/GetPendingPurchaseInvoice?companyId=` + companyId)
  }

  getNoteHistory(referenceId) {
    return this._httpClient.get<any>(`${environment.apiUrl}/PurchaseInvoice/GetHistoryList?referenceId=` + referenceId)
  }

  // InsertNote(note: string, referenceId: number) {
  //   const url = `${environment.apiUrl}/PurchaseInvoice/InsertNote?note=${note}&referenceId=${referenceId}`;
  //   return this._httpClient.post<any>(url, {});
  // }
  InsertNote(note: string, referenceId: number, noteCurrentDate: Date) {
    const url = `${environment.apiUrl}/PurchaseInvoice/InsertNote?note=${note}&referenceId=${referenceId}&noteCurrentDate=${encodeURIComponent(noteCurrentDate?.toISOString())}`;
    return this._httpClient.post<any>(url, {});
  }


  GetSupplierInvoices(data) {
    return this._httpClient.post<any>(`${environment.apiUrl}/PurchaseInvoice/GetSupplierInvoices`, data);
    // const url = `${environment.apiUrl}/PurchaseInvoice/GetSupplierInvoices?SupplierId=${SupplierId}&PaymentTypeId=${PaymentTypeId}`;
    // return this._httpClient.post<any>(url, {})
  }

  GetPurchaseInvoiceStatusHistory(PurchaseInvoiceId) {
    return this._httpClient.get<any>(`${environment.apiUrl}/PurchaseInvoice/GetPurchaseInvoiceStatusHistory?PurchaseInvoiceId=` + PurchaseInvoiceId)
  }
  GetTaxRates() {
    return this._httpClient.get<any>(`${environment.apiUrl}/PurchaseInvoice/GetTaxRates`)
  }
  GetPurchaseInvoiceCSV(listpurchaseInvoiceId: number[]) {
    const url = `${environment.apiUrl}/purchaseinvoice/GetPurchaseInvoiceCSV`;
    return this._httpClient.post<any>(url, listpurchaseInvoiceId);
  }

  GetExportCSVHistory() {
    return this._httpClient.get<any>(`${environment.apiUrl}/PurchaseInvoice/GetExportCSVHistory`)
  }
  GetPurchaseInvoiceXeroCSV(listpurchaseInvoiceId: number[]) {
    const url = `${environment.apiUrl}/purchaseinvoice/GetPurchaseInvoiceXeroCSV`;
    return this._httpClient.post<any>(url, listpurchaseInvoiceId);
  }

  GetAgreedPurchaseInvoices(obj) {
    return this._httpClient.post<any>(`${environment.apiUrl}/PurchaseInvoice/GetAgreedPurchaseInvoices`,obj)
  }

  UpdateSelectedExportXeroBills(obj) {
    return this._httpClient.post<any>(`${environment.apiUrl}/PurchaseInvoice/UpdateSelectedExportXeroBills`,obj)
  }

  GeneratetPurchaseInvoiceXeroCSV(listpurchaseInvoiceId: number[],isAllSelected:boolean) {
    const url = `${environment.apiUrl}/purchaseinvoice/GeneratetPurchaseInvoiceXeroCSV?isAllSelected=${isAllSelected}`;
    return this._httpClient.post<any>(url, listpurchaseInvoiceId);
  }

}
