<!-- <p>user-company-add works!</p> -->
<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="card">
      <div class="row match-height">
        <!-- Payment Start Here -->
        <div class="col-12">
          <h2 class="m-2">Manage User Companies</h2>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div style="display: flex; flex-direction: row; gap:10px">
                    <span style="color:rgb(180, 173, 173); font-size: 1rem;">Full name: </span> <span>{{
                      userObj?.username }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <a class="add-icon" placement="left" ngbTooltip="Add Company" (click)="addLineItem()">Add Company</a>
            </div>
          </div>
        </div>
        <hr />


        <div class="col-12 ">
          <div class="card-body ">
            <form class="form" [formGroup]="myForm">

              <div class="col-12 companyBody">
                <div class="card-body companyBody">
                  <div class="row Company_div" *ngFor="let item of lineItems; let i = index">

                    <div class="col-md-4  dropdown" *ngIf="user_dropdown">
                      <div class="form-group">
                        <label for="CompanyId">Company</label>
                        <select [ngModelOptions]="{standalone: true}" class="form-control" (change)="onSelectCompany(item.companyId, i)" [(ngModel)]="item.companyId"
                          name="CompanyId">
                          <option *ngFor="let items of companyList[i]" value="{{items.companyId}}">
                            {{items.companyName}}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-4 dropdown" *ngIf="user_dropdown">
                      <div class="form-group">
                        <label for="UserId">Role</label>
                        <select [ngModelOptions]="{standalone: true}" class="form-control" name="RoleId"
                          [(ngModel)]="item.roleId">
                          >
                          <option *ngFor="let items of roleList" value="{{items.roleId}}">
                            {{items.roleName}}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- <div>
                    </div> -->
                    <div class="d-flex align-items-center" *ngIf="user_dropdown ">
                      <a class="delete_icon" placement="left" ngbTooltip="Delete Company" (click)="removeLineItem(i)"><i
                          style="font-size: 20px; color: white;" class="fa fa-trash " aria-hidden="true"></i></a>
                    </div>
                  </div>

                  <!-- <div class="row">
                    <a class="add-icon" placement="left" ngbTooltip="Add Company" (click)="addLineItem()">Add Company</a>
                  </div> -->


                </div>
              </div>

            </form>
            <div class="modal-footer">
              <button (click)="backToList()" rippleEffect class="btn btn-danger mr-1">
                Exit
              </button>
              <button type="submit" (click)="onSubmit()" class="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
<block-ui></block-ui>
</div>