export const locale = {
  lang: 'de',
  data: {
    HEADER: {
      NAME: 'NAME',
      EMAIL: 'EMAIL',
      AGE: 'ALTER',
      STATUS: 'STATUS',
      ACTIONS: 'AKTIONEN'
    }
  }
};
