import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatablesModule } from 'app/main/tables/datatables/datatables.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { RouterModule, Routes } from '@angular/router';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CoreCommonModule } from '@core/common.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { BlockUIModule } from 'ng-block-ui';
import { SaleInvoiceAddComponent } from './sale-invoice-add/sale-invoice-add.component';
import { SaleInvoiceListComponent } from './sale-invoice-list/sale-invoice-list.component';
import { SharedModule } from 'app/shared/shared.module';


const routes: Routes = [
  {
    path: 'saleinvoiceadd',
    component: SaleInvoiceAddComponent,
  },
  {
    path: 'sale-List',
    component: SaleInvoiceListComponent,
  },
  {
    path: 'saleinvoiceadd/:id',
    component: SaleInvoiceAddComponent,
  },
 
];

@NgModule({
  declarations: [
    SaleInvoiceAddComponent,
    SaleInvoiceListComponent,
  ],
  imports: [
    DatatablesModule,
    CommonModule,
    RouterModule.forChild(routes),
    NgxDatatableModule,
    Ng2FlatpickrModule,
    NgbModule,
    CoreCommonModule,
    CardSnippetModule,
    ContentHeaderModule,
    NgSelectModule,
    SweetAlert2Module.forRoot(),
    ContentHeaderModule,
    CorePipesModule,
    FormsModule,
    BlockUIModule.forRoot(),
    SharedModule
  ],

  // providers: [SupplierService],
  // exports: [SuppliersListComponent]
})
export class SaleinvoiceModule { }
