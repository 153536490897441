<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="card">
            <div class="row match-height">
                <!-- Payment Start Here -->
                <div class="col-12">
                    <h3 class="card-title m-2">Ok to pay</h3>
                    <div class="card-body" style="border-bottom: 2px solid #b8c2cc;">
                        <div class="row">
                            <div class="col-lg-4  col-md-6 col-12">
                                <div class="form-group row ">
                                    <div class="col-md-5 col-6 d-flex flex-column">
                                        <label><b>Invoice #</b></label>
                                        <label><b>Invoice date </b></label>
                                        <label><b>Due date</b></label>
                                    </div>
                                    <div class="col-md-7 col-6 d-flex flex-column">
                                        <span>{{invoiceObj?.invoiceNo}}</span>
                                        <span>{{invoiceObj?.invoiceDate | date: 'dd-MMM-yyyy'}}</span>
                                        <span>{{invoiceObj?.dueDate | date: 'dd-MMM-yyyy'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4  col-md-6 col-12">
                                <div class="form-group row ">
                                    <div class="col-6 d-flex flex-column">
                                        <label><b>Supplier</b></label>
                                        <label><b>Payee reference</b></label>
                                    </div>
                                    <div class="col-5  d-flex flex-column">
                                        <span>{{invoiceObj?.supplierName}}</span>
                                        <span>{{invoiceObj?.payeeReference}}</span>
                                    </div>
                                </div>
                            </div>



                            <div class="col-lg-4  col-md-6 col-12">
                                <div class="form-group row">
                                    <div class="col-6 d-flex flex-column">
                                        <label><b>Invoice Status</b></label>
                                    </div>
                                    <div class="col-5 d-flex flex-column">
                                        <span>{{invoiceObj?.invoiceStatus}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Payment Start Finish -->
                <hr />
                <!-- Details Start here -->
                <!--  -->

                <div class="col-12">
                    <!-- <h3 class="card-title m-2">Invoice Details</h3> -->
                    <div class="card-body" style="border-bottom: 2px solid #b8c2cc;">
                        <div class="table-responsive">
                            <table class="table table-hover" style="min-width: 950px;">
                                <thead>
                                    <tr>
                                        <th>Project</th>
                                        <th>Job</th>
                                        <th>Category</th>
                                        <th class="white-spaces">Account Code</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th class="text-right">Amount</th>
                                        <th class="text-right">VAT</th>
                                        <th class="text-right">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of invoiceObj?.purchaseInvoiceContentLineDTO">
                                        <td class="white-spaces">{{ item?.projectName }}</td>
                                        <td class="white-spaces">{{ item?.jobName }}</td>
                                        <td class="white-spaces">{{ item?.categoryName }}</td>
                                        <td class="white-spaces">{{ item?.taxCode }}</td>
                                        <td class="white-spaces">{{ item?.lineItemDescription }}</td>
                                        <td>{{ item?.lineItemQuantity }}</td>
                                        <td class="text-right">{{ item?.lineItemAmounts }}</td>
                                        <td class="text-right">{{ item?.lineItemVats }}</td>
                                        <td class="text-right">{{ item?.lineItemTotals }}</td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                        <div class=" d-flex  justify-content-lg-end">

                            <div class="row  p-0 m-0 mt-2 col-lg-2 col-xxl-2 col-md-4 col-sm-4 col-8">
                                <span class="col-xxl-6 col-lg-6 col-md-5 col-sm-6 col-8 white-spaces">Sub Total :
                                </span>
                                <span class="col-xxl-4 col-lg-5 col-sm-5 col-4 pr-lg-0 text-right white-spaces"> {{
                                    subTotals
                                    }}</span>
                                <span class="col-lg-6 col-md-5  col-sm-6 col-8 white-spaces">VAT : </span>
                                <span class="col-xxl-4 col-lg-5 col-sm-5 col-4 pr-lg-0 text-right white-spaces">{{
                                    vatAmounts
                                    }}</span>
                                <span class="col-lg-6 col-md-5 col-sm-6 col-8 white-spaces">Total : </span>
                                <span class="col-xxl-4 col-lg-5 col-sm-5 col-4 pr-lg-0 text-right white-spaces">{{
                                    totalInclVats
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Details Finish here -->

                <div class="col-12">
                    <div class="card-body">
                        <form class="form">
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6">
                                    <!-- <div class="form-group">
                                        <label for="datepaid">Notes</label>
                                        <textarea name="shortdescription" style="resize:none" class="form-control"
                                            id="shortdescription">{{shortdescription}}
                                </textarea>
                                    </div> -->
                                    <div *ngIf="isNotedisabled" class="form-group">
                                        <label for="note" class="col-form-label">Note History</label>
                                        <div class="input-group note_history">

                                            <div class="" *ngIf="noteList?.length > 0">
                                                <div *ngFor="let noteHistory of noteList">
                                                    <div class="row heading ">
                                                        <h6 class="col-12 m-0">{{ noteHistory?.userName }} | {{
                                                            noteHistory?.date | date: 'yyyy-MMM-dd HH:mm' }}
                                                        </h6>

                                                    </div>
                                                    <div class="" style="margin-bottom: 10px;"><span>{{
                                                            noteHistory?.note }}</span></div>
                                                </div>
                                            </div>
                                            <div class="no-history"
                                                *ngIf="noteList?.length === 0 || noteList === undefined">
                                                No history.
                                            </div>
                                        </div>
                                        <div class="noteButtonDiv">
                                            <div class="">
                                                <button class="btn btn-primary" style="width: fit-content;"
                                                    (click)="openModal()">
                                                    Add Note
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!isNotedisabled" class="col-12 px-0">
                                        <div class="form-group">
                                            <label for="note" class="col-form-label">Note</label>
                                            <textarea style="resize: none" name="note" [(ngModel)]="note"
                                                class="form-control" rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->

                                <div class="col-md-4">
                                    <!-- <div class="form-group d-flex justify-content-md-end">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" (change)="onChange(3)" [checked]="isChecked1"
                                                class="custom-control-input" id="customCheck1" />
                                            <label class="custom-control-label" for="customCheck1">I believe this
                                                invoice is OK TO PAY</label>
                                        </div>
                                    </div>
                                    <div class="form-group d-flex justify-content-md-end">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" (change)="onChange(4)" [checked]="isChecked2"
                                                class="custom-control-input" id="customCheck2" />
                                            <label class="custom-control-label" for="customCheck2">Invoice on
                                                HOLD</label>
                                        </div>
                                    </div> -->
                                    <div class="d-flex justify-content-md-end">
                                        <button (click)="backToList()" rippleEffect
                                            class="btn btn-danger mr-1">Back</button>
                                        <!-- <button [disabled]="!(isChecked1 || isChecked2)"
                                            (click)="updateInvoiceStatus();" class="btn btn-primary">Save</button> -->
                                        <button (click)="updateStatus('hold');"
                                            class="btn btn-primary mr-1">Hold</button>
                                        <button (click)="updateStatus('oktopay');" class="btn btn-primary mr-1">Ok to
                                            pay</button>
                                        <button (click)="AddNote();" class="btn btn-primary mr-1"
                                            [disabled]="note === '' || note === undefined">Save</button>
                                    </div>
                                </div>


                            </div>
                        </form>
                        <!-- <div class="modal-footer"> -->

                        <!-- </div> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>